.element {
    padding: 10px;
}
.basicInfocontainer {
    padding : 10px;
    border-style: 1px solid black;
}
.inputFormikClear {
    padding: 10px;
    background-color: var(--fill-white);
    color: black;
}
.addressTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    margin-top:24px
}
.odaContainer{
    display: flex;
    justify-content: flex-end;
}
.oda{
    width: '30%';
    margin-right: 24px;
}