.factory-container {
  background: var(--fill-grey-light6);

  > header a.nav-left {
    margin-right: 12px;
    img {
      height: 32px;
      margin: -4px 0;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px;
    background: var(--fill-white);
    box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);

    div {
      display: flex;
      align-items: center;
    }

    .navContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .mill-heading {
      font-weight: 900;
      padding-bottom: 20px;
      line-height: 23px;
      font-size: 20px;
    }

    .rightNavBtns {
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-right: 15px;
    }

    .leftNavBtns {
      display: flex;
      align-items: start;
    }

    .mill-heading-details {
      display: flex;
      flex-direction: column;
      align-items: start;

      h4 {
        padding: 0px;
        margin-bottom: 0px;
        font-weight: bold;
        font-size: 15px;
      }

      p {
        font-size: 12px;
        padding: 0px;
      }

      .red-message {
        color: var(--fill-orangered);
        background-color: var(--fill-grey-light3);
        padding: 5px 10px;
      }
    }

    h3 {
      margin: 0;
      font-size: 20px;
    }

    .chevron {
      padding: 0 8px;
      font-size: 16px;
    }
    .invisible_checkbox {
      position: absolute;
      right: 20px;
      top: 22px;
      width: 40px;
      opacity: 0;
      height: 30px;
    }
  }

  .heading--secondary {
    font-size: 12px;
    padding: 12px 0;
  }

  .details-card {
    background: var(--fill-white);
    margin: 24px;
    border-radius: 4px;
    border: solid 1px var(--fill-grey-light2);

    .card-heading {
      font-size: 16px;
      font-weight: 600;
      color: var(--fill-locofast-black);
      padding: 22px 32px;
      box-shadow: inset 0 -1px 0 0 var(--fill-grey-light3);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .card-body {
      padding: 16px 32px;

      .flex-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .factory_address {
      display: flex;
      flex-direction: column;
      width: 90%;

      > :first-child {
        div input {
          border-bottom: 0;
        }
      }

      .city {
        border-radius: 0;
        border-bottom-left-radius: 4px;
      }

      .state-select {
        border-radius: 0;
      }

      .pinCode {
        border-radius: 0;
        border-bottom-right-radius: 4px;
      }

      .factory_address-group {
        display: flex;

        div {
          flex: 1;
        }
      }
    }
  }
  .full-width {
    width: 100%;
  }

  textarea {
    resize: none;
    vertical-align: top;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .factory-attachment {
    border-radius: 2px;
    border: 1px dashed var(--fill-grey-light2);
    background-color: var(--fill-white);
    margin-bottom: 1em;
    max-width: 285px;
    min-width: 285px;
    height: 128px;
    display: flex;
    align-items: stretch;

    .input-file__file-thumb {
      border-radius: 4px;
      overflow: hidden;
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      border-radius: 4px;
      background-color: var(--fill-grey-light2);
      border-radius: 50%;
      cursor: pointer;

      img {
        margin-left: 10px;
      }
    }

    .input-file__wrapper {
      &.empty {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 15px;
      }

      > div.full_length {
        height: 100%;
        justify-content: space-around;
      }
    }

    .input-file__wrapper.selected {
      width: 100%;

      .flex-col {
        width: 100%;
      }
    }

    .input-file__progress {
      flex-grow: 1;
      padding: 0px 6px;
      align-self: baseline;
    }

    .input-file__delete {
      margin-left: auto;
      padding-right: 6px;
      cursor: pointer;
      user-select: none;
    }

    .input-file__percent {
      font-size: 12px;
      color: var(--fill-grey-medium);
    }

    .input-file__meta {
      color: var(--fill-grey-medium);
      line-height: 1.43;
      letter-spacing: 0.2px;
      user-select: none;
      align-self: flex-start;
    }

    .input-header__text {
      color: var(--fill-grey-dark);
    }

    .input-file__meta-selected {
      width: 100%;
      display: flex;
      padding: 16px;
      align-items: center;
      font-size: 12px;
      text-shadow: none;
    }

    .input-file__default-thumb {
      width: 36px;
      height: 44px;
      border-radius: 4px;
      margin: auto;
    }

    .attachment_meta {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 16px;
      background: var(--fill-grey-light5);
      color: var(--fill-grey-medium);
    }

    .input-file__cover-img {
      margin: 1em -8px;
      margin-bottom: -0.5em;
      padding: 1em;
      align-items: center;
      color: var(--fill-grey-dark);
      font-size: 11px;
      font-weight: 600;
    }
  }

  .details-error {
    padding: 20px 40px;
    > .error {
      font-size: 18px;
    }
  }

  .select-container {
    .label__class {
      margin-bottom: 4px;
    }
  }

  .select-container.brand {
    flex: 1;
    .downshift {
      margin-right: 10%;
    }
  }

  .lf-supplier-radio {
    margin-top: 16px;

    > div:first-child {
      padding-bottom: 16px;
    }
  }
}
.factory-add-edit-container > .scrollbar-container {
  background-color: var(--fill-grey-light6);
}

.pill {
  background: #1889e6;
  border-radius: 16px;
  color: var(--fill-white);
  margin-bottom: 8px;
  margin-right: 3px;
  padding: 8px 20px;
  text-align: center;
  width: fit-content;
}

.pill-2 {
  background: rgb(73, 158, 207);
  border-radius: 16px;
  color: var(--fill-white);
  margin-bottom: 8px;
  margin-right: 3px;
  padding: 4px 20px;
  text-align: center;
}

.pill-3 {
  background: rgb(92, 191, 248);
  border-radius: 16px;
  color: var(--fill-white);
  margin-bottom: 8px;
  margin-right: 3px;
  padding: 4px 8px;
  text-align: center;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1
}

.millDetailsToggles {
  .status-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 5px 12px;
  }
}

.radioGroup {
  .ant-radio-group {
    display: flex;
    align-items: center;
  }
}

.text-center{
  text-align: center;
}

.millModalInput {
  padding: 20px 10px;
}

.millEditBtn {
  border: none;
  background: none;
  color: blue;
  cursor: pointer;
}

.modalInpContainer {
  margin-bottom: 20px;
}
.dateInput{
  display: flex;
  flex-direction: column;
}
.modalDetailsContainer {
  margin-top: 10px;
  margin-bottom: 20px;

  .inputField {
    margin-top: 8px;
  }
}

.mt-5 {
  margin-top: 10px;
}
.mt-8 {
  margin-top: 32px;
}
.mb-8 {
  margin-bottom: 32px;
}
.mt-12 {
  margin-top: 48px;
}
.mt-4 {
  margin-top: 16px;
}
.mr-1 {
  margin-right: 4px;
}

.ml-1 {
  margin-left: 4px;
}

.mr-2 {
  margin-right: 8px;
}
.mr-6 {
  margin-right: 24px;
}
.ml-2 {
  margin-left: 8px;
}

.ptb-12 {
  padding: 12px 0;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-15 {
  padding-bottom: 15px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.inputImageAttachment {
  width: 90%;
  overflow: hidden,
}

.w-full {
  width: 100%;
}
.footer {
  display: flex;
  justify-content: end;
  margin: 57px 0px 20px;
}