
.upload-wrapper {
  display: grid;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  position: relative;

  .label {
    font-size: 0.75rem;
    color: black;
    justify-content: space-between;
    display: flex;
  }

  .error {
    position: absolute;
    bottom: -1.25rem;
    font-size: 0.75rem;
    color: red;
  }
}
.text-wrapper-download {
  width: fit-content;
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  line-height: 2;
  padding-left: 4px;
}

.download-box {
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-color: #E5E5E5;
  padding:8px;
  border-radius: 8px;
}

.download-btn {
  height: 47px;
  width: 305px;
  border-radius: 4px;
}
