.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: rgb(255, 255, 255);
}
.topline {
  padding: 10px 10px 0px 10px;
}

.brandimg {
  padding: 0 10px;
}

.header-right-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  padding: 0.5rem 1rem;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}
.commentBox {
  width: 350px;
  margin-left: 10px;
}
.separator {
  color: #D4D4D4;
  margin: 0 5px;
  font-weight: normal; 
}
.title-bold {
  color: var(--neutral-black, #00142d);
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.subtitle {
  color: var(--fill-grey-dark2);
}
.select-gap {
  margin-top: 32px;
}
.am-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; 
  gap: 3px;
}

.am-item {
  display: flex;
  align-items:center;
}

.am-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}