@import '../../../../../assets/scss/colors.scss';


.statusBox{
    display: flex;
    gap: 4px;


    .chevron{
        cursor: pointer;
    }

    .status15, .status20, .status30 {
        color: var(--fill--primary-rusty-orange);
    }
    .status40 {
        color: var(--fill-success);
    }
    .status50 {
        color: red;
    }
}

