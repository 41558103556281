// @import 'styles';

.input-wrapper {
  display: grid;
  gap: 0.5rem;
  position: relative;

  .label {
    font-size: 0.75rem;
    justify-content: space-between;
    display: flex;
    margin-bottom:0px;
  }
  .box {
    display: flex;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .input {
    padding: 0.625rem 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid var(--fill-grey-light8);
    font-size: 1rem;
    width: 100%;
    height: 3rem;
  }
  .secondinput {
    padding: 0.625rem 0.75rem;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border: 1px solid var(--fill-grey-light8);
    border-right: 0px;
    font-size: 1rem;
    height: 3rem;
  }
  .unit {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: var(--fill-grey-dark2);
    width: 90px;
    padding: 10px 10px;
    background-color: var(--fill-grey-light5);
  }

  .error {
    font-size: 0.75rem;
    color: red;
  }
  .multiSelectTextInput > div {
    min-height: 42px;
    border: 1px solid var(--fill-grey-light8);
    > div div {
    color: var(--fill-grey-medium3);
    }
  }
}
